import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { styles } from 'app/theme'
import React, { memo } from 'react'

export interface Props {
  items: string[]
  title?: string
}

export const Info = memo(function Info({ items, title }: Props) {
  return (
    <Container>
      <Wrapper>
        {title ? <Title>{title}</Title> : null}
        {items ? (
          <Items>
            {items.map((item: any, index) => (
              <Item key={index}>
                {item.label ? (
                  <FadeInUp>
                    <Label dangerouslySetInnerHTML={{ __html: item.label }} />
                  </FadeInUp>
                ) : null}
                {item.description ? (
                  <FadeInUp>
                    <Description
                      dangerouslySetInnerHTML={{ __html: item.description }}
                    />
                  </FadeInUp>
                ) : null}
              </Item>
            ))}
          </Items>
        ) : null}
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  margin: 9.375rem 8.938vw 0;

  @media (max-width: 1199px) {
    width: calc(100% - 2.1875rem);
    margin: 5rem 0 0 2.1875rem;
  }
`

const Wrapper = styled.div`
  position: relative;
  max-width: 68.125rem;
  margin: 0 auto;
  padding: 10.3125rem 6.875rem 9.375rem;
  background-color: ${({ theme }) => theme.colors.variants.neutralLight4};

  @media (max-width: 1199px) {
    max-width: 100%;
    padding: 8.3125rem 2.5rem 5.375rem;
  }
`

const Title = styled.div`
  ${styles.label}
  position: absolute;
  top: 8.125rem;
  left: -6.875rem;

  @media (max-width: 1199px) {
    top: 6.125rem;
    left: -0.9375rem;
  }
`

const Items = styled.div``

const Item = styled.div`
  margin-top: 3.125rem;

  @media (max-width: 1199px) {
    margin-top: 1.875rem;
  }

  &:not(:first-of-type) {
    margin-top: 4.5rem;

    @media (max-width: 1199px) {
      margin-top: 2.5rem;
    }
  }
`

const Label = styled.h2`
  ${styles.h3}
  margin-bottom: 1.0625rem;
`

const Description = styled.div`
  ${styles.description}
`
